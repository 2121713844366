import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import FAQs from "../components/Repeating/FAQs";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedLoanPrograms from "../components/Repeating/RelatedLoanPrograms";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import lowDownPayment from "../images/2.0 Loan Programs/Conventional/Low Down Payment.svg";
import affordableClosingCosts from "../images/2.0 Loan Programs/Conventional/Affordable Closing Costs.svg";
import rewardGoodCredit from "../images/2.0 Loan Programs/Conventional/A Reward for Good Credit.svg";
import oftenPreferredSellers from "../images/2.0 Loan Programs/Conventional/Often Preferred by Sellers.svg";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "What housing markets do you serve?",
      answer: (
        <>
          <p className="mb-0">
            We serve eight states, with areas as diverse as{" "}
            <Link
              to="/tempe-mortgage-lender/"
              className="font-normal text-typography-body no-underline"
            >
              Tempe
            </Link>
            , Arizona,{" "}
            <Link
              to="/las-vegas-mortgage-lender/"
              className="font-normal text-typography-body no-underline"
            >
              Las Vegas
            </Link>
            , Nevada, and{" "}
            <Link
              to="/san-bernardino-mortgage-lender/"
              className="font-normal text-typography-body no-underline"
            >
              San Bernardino
            </Link>
            , California.
          </p>
        </>
      ),
    },
    {
      question: "What do conforming and non-conforming mean?",
      answer: (
        <>
          <p>
            Conventional loans are considered either conforming or
            non-conforming.
          </p>
          <p>
            A conforming loan meets the standards of Fannie Mae and Freddie Mac
            (government-sponsored enterprises that invest in mortgage loans).{" "}
          </p>
          <p className="mb-0">
            Non-conforming loans do not meet Fannie Mae and Freddie Mac
            standards—usually because they either don’t meet a requirement set
            by the Federal Housing Finance Agency (FHFA) or the loan amount is
            too large.
          </p>
        </>
      ),
    },
    {
      question: "What types of homes can I purchase with a conventional loan?",
      answer: (
        <>
          <p>
            You can purchase many types of homes with a conventional home loan,
            including a:
          </p>
          <ul className="styled-list-checkmark">
            <li>Single-family home</li>
            <li>Condominium</li>
            <li>Manufactured home</li>
            <li>Vacation home</li>
            <li>Investment, such as a multi-unit property (2, 3 or 4 units)</li>
          </ul>
        </>
      ),
    },
    {
      question: "What documents will I need to provide?",
      answer: (
        <>
          <p>
            You’ll need to provide the following when applying for a
            conventional home loan:
          </p>
          <ul className="styled-list-checkmark">
            <li>A copy of your driver’s license</li>
            <li>
              2 years of W2s (or 2 years of full tax returns if you’re
              self-employed)
            </li>
            <li>2 of your most recent pay stubs</li>
            <li>
              A copy of your mortgage statement (if you currently own a home)
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Do I need a certain income to qualify?",
      answer: (
        <>
          <p className="mb-0">
            No. Lenders look more at your debt-to-income (DTI) ratio than your
            income. That tells them whether you can afford a mortgage within
            your budget.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Conventional Home Loans | Right Start | Mortgage Lender"
        description="Learn how to qualify for a conventional home loan, which is issued by a private lender, such as a bank or credit union. Discover the benefits!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/2.0 Loan Programs/Conventional/1.0 conventional-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>Conventional Home Loans</h1>
              <p>
                Any mortgage loan that’s not insured or guaranteed by the
                government is considered a conventional loan. Conventional home
                loans are issued by private lenders, such as banks or credit
                unions.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>Your Guide to Conventional Loans</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Who it’s For"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Benefits"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Eligibility"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Who It’s For</h3>
                <p className="mb-0">
                  Borrowers with strong credit, a solid income, and at least 3%
                  for the down payment are the best candidates for a
                  conventional home loan. Both first-time and experienced home
                  buyers can qualify if they meet the requirements.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-10 md:mb-12">
                  <h3 className="heading-four">The Benefits</h3>
                </header>

                <div className="grid gap-8 md:grid-cols-2 md:gap-10">
                  <div>
                    <img
                      src={rewardGoodCredit}
                      alt="A Reward for Good Credit"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">A Reward for Good Credit</h3>
                    <p className="mb-0">
                      The better your credit score, the better your interest
                      rate with a conventional home loan.
                    </p>
                  </div>

                  <div>
                    <img
                      src={lowDownPayment}
                      alt="Low Down Payment"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Low Down Payment</h3>
                    <p className="mb-0">
                      You can put as little as 3% down when purchasing a home
                      using a conventional loan.
                    </p>
                  </div>

                  <div>
                    <img
                      src={oftenPreferredSellers}
                      alt="Often Preferred by Sellers"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Often Preferred by Sellers</h3>
                    <p className="mb-0">
                      Conventional loans tend to be appealing to sellers and can
                      give you an edge over the competition.
                    </p>
                  </div>

                  <div>
                    <img
                      src={affordableClosingCosts}
                      alt="Affordable Closing Costs"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Affordable Closing Costs</h3>
                    <p className="mb-0">
                      Conventional home loans don’t have the funding fees that
                      all government loans require, making them cheaper to
                      close.
                    </p>
                  </div>
                </div>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Eligibility Requirements</h3>
                <ul className="styled-list-checkmark">
                  <li>Credit score of 620 or higher</li>
                  <li>Debt-to-income ratio no higher than 45%</li>
                  <li>Minimum down payment of 3% (or 20% for no PMI)</li>
                  <li>
                    Appraisal that verifies the home’s value and condition
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQs heading="FAQs" uniqueFaqs={faqs} />
      <Testimonials />
      <ValueProps />
      <About />
      <RelatedLoanPrograms hiddenProgram={3} />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Conventional.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Conventional.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
